<!-- eslint-disable prettier/prettier -->

<template>
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top"></div>
    <v-card
      class="radius-card d-flex"
      style="box-sizing: border-box"
      min-height="500px"
      flat
      v-if="person"
    >
      <div class="toolbar pa-3">
        <div class="d-flex align-center mt-3 mb-3">
          <img
            :src="
              person.photo_profile
                ? `${env}/upload/photo_profile/${person.id}/${person.photo_profile}`
                : dummy
            "
            alt=""
            class="dp mr-5"
          />
          <div>
            <h3>{{ person.nama_lengkap }}</h3>
            <p class="small_txt">
              {{ person.data_jurusan ? person.data_jurusan.nama : "" }} di
              {{ person.data_kota1.nama_kota }}
            </p>
          </div>
        </div>

        <v-card
          class="pa-3 mb-3"
          flat
          v-if="person.data_histori_pendidikan.length"
        >
          <section class="d-flex">
            <v-icon class="mr-2">mdi-school</v-icon>
            <h4>Histori pendidikan</h4>
          </section>
          <section
            v-for="(item, idx) in person.data_histori_pendidikan"
            :key="idx"
            class="ml-7"
          >
            <p class="ma-0 color_txt">
              {{ item.tingkat }} di {{ item.nama_lembaga_pendidikan }},
              {{ item.tahun_lulus }}
            </p>
          </section>
        </v-card>
        <v-card class="pa-3 mb-5" flat v-if="person.data_rekomendasi.length">
          <section class="d-flex">
            <v-icon class="mr-2">mdi-comment-quote</v-icon>
            <h4>Rekomendasi dari psycholog lain</h4>
          </section>
          <v-carousel
            v-model="slide"
            hide-delimiters
            height="auto"
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="item in person.data_rekomendasi"
              :key="item.id"
            >
              <v-card class="pa-2 white" height="100%">
                <v-card
                  class="
                    radius-card
                    py-2
                    px-5
                    white
                    d-flex
                    flex-column
                    justify-space-between
                  "
                  height="100%"
                >
                  <section class="black--text mb-3">
                    <v-icon color="grey">mdi-format-quote-open</v-icon>
                    <p class="ma-0">
                      {{ item.catatan }}
                    </p>
                    <div class="d-flex justify-end">
                      <v-icon color="grey">mdi-format-quote-close</v-icon>
                    </div>
                  </section>
                </v-card>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card>
        <v-btn
          dark
          color="#3b053c"
          depressed
          block
          to="/counseling/find-psycolog"
        >
          <v-icon class="mr-2">mdi-magnify</v-icon> Cari Psikolog Lain
        </v-btn>
      </div>
      <div class="list_psy">
        <div class="header d-flex align-center pa-3">
          <div class="circle_ttl mr-2">
            <div class="litle_"></div>
          </div>
          <h3>{{ step == 1 ? "Pilih Paket" : "Pilih Jadwal" }}</h3>
        </div>
        <div class="pa-3">
          <v-card
            color="#eae8e8"
            class="radius-card pa-3"
            flat
            v-if="step == 1"
          >
            <v-row>
              <v-col
                cols="6"
                v-for="(item, idx) in listPackage"
                :key="`paket-${idx}`"
              >
                <v-card>
                  <div class="pa-3">
                    <h4 class="text-center color_purple">
                      {{ item.nama }}
                    </h4>
                    <v-card outlined class="pa-2 my-2">
                      <div class="d-flex align-center justify-center">
                        <p class="ma-0 small_txt color_txt mr-5">Durasi</p>
                        <div class="d-flex align-center">
                          <h3 class="ma-0">{{ item.durasi }}</h3>
                          <p class="ma-0 small_txt color_txt">Menit</p>
                        </div>
                      </div>
                      <div class="d-flex align-center justify-center">
                        <p class="ma-0 small_txt color_txt mr-5">Harga</p>
                        <div class="d-flex align-center">
                          <p class="ma-0 small_txt color_txt mr-1">Rp.</p>
                          <b class="ma-0">{{ item.harga }}</b>
                        </div>
                      </div>
                      <div class="d-flex align-center justify-center">
                        <b class="ma-0 mr-2"
                          >{{ item.sesi ? item.sesi : 1 }}x</b
                        >
                        <p class="ma-0 small_txt color_txt">Pertemuan</p>
                      </div>
                    </v-card>
                    <v-btn
                      block
                      class="text-capitalize mt-2"
                      rounded
                      color="#3b053c"
                      depressed
                      :outlined="selectedPackage.id !== item.id"
                      :dark="selectedPackage.id == item.id"
                      @click="pickedPackage(item)"
                      >{{
                        selectedPackage.id == item.id ? "Terpilih" : "Pilih"
                      }}</v-btn
                    >
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-card
            color="#eae8e8"
            class="radius-card pa-3"
            flat
            v-if="step == 2"
          >
            <v-menu
              v-model="calendar"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  class="pa-2 rounded d-flex justify-space-between align-center"
                >
                  <p class="ma-0 small_txt">
                    {{ date ? date : "Pilih Tanggal" }}
                  </p>
                  <v-icon>mdi-calendar</v-icon>
                </v-card>
              </template>
              <v-date-picker
                v-model="date"
                :min="dateNow"
                @input="parseSchedule()"
              ></v-date-picker>
            </v-menu>

            <v-card class="pa-3 rounded mt-3" flat v-if="dayPicked">
              <v-btn-toggle
                v-if="dayPicked.time.length"
                flat
                v-model="hourPicked"
                active-class="active_time"
                borderless
              >
                <div class="d-flex flex-wrap">
                  <v-btn
                    class="ma-2"
                    depressed
                    v-for="(item, idx) in dayPicked.time"
                    :key="idx"
                    :value="item"
                  >
                    {{ item }}
                  </v-btn>
                </div>
              </v-btn-toggle>
              <div class="pa-3" v-else>
                <h3>
                  Tidak tersedia di tanggal ini, silakan pilih tanggal lain.
                </h3>
              </div>
            </v-card>
          </v-card> -->
          <v-card
            v-if="step == 2"
            color="#eae8e8"
            class="radius-card pa-3"
            flat
          >
            <h4 class="mb-2">Tulis Desripsi masalah</h4>
            <v-textarea
              outlined
              @keyup="checkChara"
              :error-messages="minChara"
              color="purple"
              v-model="permasalahan"
              background-color="white"
              counter
            ></v-textarea>
            <div class="d-flex align-center">
              <v-checkbox v-model="term">
                <template v-slot:label> </template>
              </v-checkbox>
              <p class="small_txt ma-0">
                Saya menyetujui
                <span
                  class="font-weight-bold blue--text cursor"
                  @click="d_term = true"
                  >syarat dan ketentuan
                </span>
                yang berlaku
              </p>
            </div>
          </v-card>

          <div class="d-flex justify-center">
            <v-btn
              class="mt-2 mr-2"
              v-if="step > 1"
              @click="step--"
              color="#3b053c"
              dark
              depressed
              >Back</v-btn
            >
            <v-btn
              class="mt-2"
              v-if="step == 1 && isPackagePicked"
              color="#3b053c"
              dark
              @click="nextStep()"
              depressed
              >Next</v-btn
            >
            <!-- <v-btn
              class="mt-2"
              v-if="step == 2 && permasalahan"
              color="#3b053c"
              dark
              @click="nextStep()"
              depressed
              >Next</v-btn
            > -->
            <v-btn
              class="mt-2 ml-2"
              v-if="step == 2 && (permasalahan.length > 500) && term"
              color="#3b053c"
              dark
              depressed
              @click="bookNow"
              >Pesan Sekarang</v-btn
            >
          </div>
        </div>
      </div>
      <TermCounseling :d_term="d_term" @close="d_term = false" />
      <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="purple"
        ></v-progress-circular>
        <b class="purple--text">Loading...</b>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import TermCounseling from "../../Public/Modal/termCounseling.vue";
export default {
  name: "prepareBook",
  components: { TermCounseling },
  computed: {
    ...mapState({
      env: state => state.API_URL,
      dummy: state => state.dummy,
      id: state => state.id,
      profile: state => state.cust.myProfile
    })
  },
  data() {
    return {
      dateNow: this.$date().format("YYYY-MM-DD"),
      loading: false,
      d_term: false,
      slide: null,
      page: 1,
      limit: 6,
      step: 1,
      total_page: null,
      tempId: null,
      person: null,
      calendar: false,
      date: null,
      dayPicked: null,
      permasalahan: "",
      term: false,
      hourPicked: null,
      listPackage: null,
      isPackagePicked: false,
      listSchedule: null,
      finalStep: false,
      selectedPackage: {
        id: 0
      },
      minChara: "",
      charaPassed: false
    };
  },
  mounted() {
    this.fetchData();
    this.fetchPackage();
  },
  methods: {
    nextStep() {
      if (this.step == 1) {
        this.step = 2;
      } else {
        this.step = 3;
        this.finalStep = true;
      }
    },
    fetchData() {
      this.loading = true;
      let data = this.$route.params.id;
      this.$store.dispatch("counseling/viewPsycholog", data).then(data => {
        this.person = data;
        this.listSchedule = data.my_schedule;
        this.loading = false;
      });
    },
    fetchPackage() {
      let data = this.$route.params.id;
      this.$store.dispatch("counseling/getPackage", data).then(data => {
        this.listPackage = data;
      });
    },
    pickedPackage(item) {
      this.selectedPackage = item;
      this.isPackagePicked = true;
    },
    checkChara() {
      let length = this.permasalahan.length;
      if (length < 500) {
        this.minChara = "Minimal 500 karakter!";
        this.charaPassed = false;
      } else {
        this.minChara = "";
        this.charaPassed = true;
      }
    },
    parseSchedule() {
      this.calendar = false;
      let day = "";
      switch (this.$date(this.date).format("dd")) {
        case "Su":
          day = "Minggu";
          break;
        case "Mo":
          day = "Senin";
          break;
        case "Tu":
          day = "Selasa";
          break;
        case "We":
          day = "Rabu";
          break;
        case "Th":
          day = "Kamis";
          break;
        case "Fr":
          day = "Jumat";
          break;
        case "Sa":
          day = "Sabtu";
          break;
      }
      let sameDay = null;
      sameDay = this.listSchedule.find(el => el.day == day);
      if (sameDay) {
        this.dayPicked = sameDay;
      } else {
        this.dayPicked = {
          time: []
        };
      }
    },
    bookNow() {
      this.loading = true;
      let data = {
        user_id: this.person.id,
        price: this.selectedPackage.harga,
        konsultasi_paket_id: this.selectedPackage.id,
        session: this.selectedPackage.sesi ? this.selectedPackage.sesi : 1,
        duration: this.selectedPackage.durasi,
        jenis_kelamin: this.profile.jenis_kelamin,
        usia: this.profile.tanggal_lahir.umur,
        permasalahan: this.permasalahan
      };
      this.$store
        .dispatch("counseling/letsBook", data)
        .then(data => {
          if (data.status == "success") {
            this.Swal("success", data.message);
            let id = data.data.data_transaction.id;
            this.$router.push(`/counseling/detail-transaction/${id}`);
          } else {
            this.Swal("warning", data.message);
          }
          this.loading = false;
        })
        .catch(e => {
          if (e.data) {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          }
          this.loading = false;
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family"
        }
      });
    }
  }
};
</script>

<style scoped>
.toolbar {
  width: 50%;
  background: #eae8e8;
  border-radius: 10px 0 0 10px !important;
}
.list_psy {
  width: 50%;
}
.another_filter {
  background: #eae8e8;
  padding: 5px;
  width: max-content;
  margin-bottom: -15px;
}
.circle_ttl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3b053c;
}
.litle_ {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}
.pp {
  width: 100px;
}
.dp {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}
.active_time {
  background: #3b053c !important;
  color: #fff !important;
}
</style>
