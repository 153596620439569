<template>
  <div>
    <v-dialog v-model="d_term" persistent max-width="500px">
      <v-card class="radius-card pa-3">
        <div class="d-flex justify-space-between align-center">
          <h3>Term And Conditions</h3>
          <v-btn fab small icon @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <ul>
          <li class="mb-2">
            Saya memahami bahwa konseling yang akan dilakukan terdiri atas
            beberapa beberapa kegiatan, yaitu asesmen, konseling, pemberian
            terapi serta penegakan diagnosis jika diperlukan.
          </li>
          <li class="mb-2">
            Saya bersedia mengikuti layanan konseling ini tanpa adanya paksaan
            dari pihak manapun.
          </li>
          <li class="mb-2">
            Saya bersedia untuk berpartisipasi aktif dalam diskusi Bersama
            psikolog terkait cara penyelesaian masalah saya.
          </li>
          <li class="mb-2">
            Saya saat ini tidak sedang menyakiti atau membahayakan diri sendiri
            (self-harm/suicidal attempts).
          </li>
        </ul>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "terms",
  props: ["d_term"],
  data() {
    return {
      step: "topic"
    };
  }
};
</script>

<style></style>
